<template>
  <div class="">
    <h3>
      Ingresos (Venta al cliente) Pricing:
      {{ parseFloat(ingreso_pr).toFixed(2) }} USD / ------- Operaciones:
      {{ parseFloat(ingreso_op).toFixed(2) }} USD
    </h3>
    <v-expansion-panels v-model="openedPanel" accordion>
      <v-expansion-panel
        v-for="(house, index) in ($store.state.controlGastos
          .listControlGastos[0] &&
          $store.state.controlGastos.listControlGastos[0].master_houses) ||
        []"
        :key="index"
        :value="house.isExpanded"
      >
        <v-expansion-panel-header @click.stop="abrirExpandIngreso(index)">
          <template #actions>
            <v-icon @click="abrirExpandIngreso(index)"
              >mdi-unfold-more-horizontal</v-icon
            >
          </template>
          <v-simple-table dense>
            <thead>
              <tr>
                <th width="12%">House</th>
                <th width="24%">Consignatario</th>
                <th width="12%" style="background: #d6f4ff">Total Pricing</th>
                <th width="12%" style="background: #ffd6d6">
                  Total Operaciones
                </th>
                <th width="12%" style="background: #fff9c4">Total Deuda</th>
                <th width="6%">Acción</th>
                <th width="6%">Abonos</th>
                <th width="8%">
                  Proformas del Sistema
                  <p class="mb-0 text-no-wrap" style="font-size: 0.7rem">
                    Fecha Última Factura:
                    {{
                      (house.ingresos.length &&
                        house.ingresos[house.ingresos.length - 1].fecha) ||
                      "S/E"
                    }}
                  </p>
                </th>
                <th width="8%">Facturas Fiscales</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ house.code_house }}</td>
                <td>{{ house.consigner }}</td>
                <td>{{ house.total_total_pr_ingresos }}</td>
                <td>{{ house.total_total_op_ingresos }}</td>
                <td>{{ getDeudaActual(house) }}</td>
                <td>
                  <v-btn
                    v-if="house.id_orders"
                    @click="nuevoIngreso(house)"
                    color="blue"
                    dark
                    small
                    @click.stop="abrirExpandIngreso(index)"
                  >
                    Nuevo Concepto

                    <!-- <v-icon>mdi-plus</v-icon> -->
                  </v-btn>
                  <v-btn
                    v-if="!house.id_orders"
                    small
                    @click="nuevoControlGasto(house)"
                    color="green darken-3"
                    dark
                    @click.stop="abrirExpandIngreso(index)"
                    >Nuevo Control de Gastos Ingresos</v-btn
                  >
                </td>
                <td>
                  <v-btn
                    v-if="house.id_orders"
                    :color="house.debscliente.length > 0 ? 'green' : 'red'"
                    :dark="
                      house.total_total_pr_ingresos != 0 ||
                      house.total_total_op_ingresos != 0
                    "
                    :disabled="
                      house.total_total_pr_ingresos == 0 &&
                      house.total_total_op_ingresos == 0
                    "
                    small
                    @click="_openDebs(house)"
                    @click.stop="abrirExpandIngreso(index)"
                  >
                    CARGAR ABONOS
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    v-if="house.id_orders"
                    color="pink"
                    small
                    :dark="!isBotonEmitirProformaDisabled(house)"
                    :disabled="isBotonEmitirProformaDisabled(house)"
                    @click="abrirModalFacturar(house)"
                    @click.stop="abrirExpandIngreso(index)"
                  >
                    EMITIR PROFORMA
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    color="primary"
                    small
                    @click.stop="abrirModalFacturasFiscales(house)"
                  >
                    PROFORMA FISCAL
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">Descripción</th>
                <th style="background: #d6f4ff" class="text-left">Monto Pr</th>
                <th
                  style="background: #d6f4ff"
                  class="text-left"
                  v-if="mostrarImpuesto"
                >
                  {{ $store.state.enterprises.impuesto.nombre_impuesto }} Pr
                </th>
                <th style="background: #d6f4ff" class="text-left">Total Pr</th>
                <th style="background: #ffd6d6" class="text-left">Monto Op</th>
                <th
                  style="background: #ffd6d6"
                  class="text-left"
                  v-if="mostrarImpuesto"
                >
                  {{ $store.state.enterprises.impuesto.nombre_impuesto }} Op
                </th>
                <th style="background: #ffd6d6" class="text-left">Total Op</th>
                <th class="text-center">Facturado</th>
                <th class="text-left">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item of house.ingresos" :key="item.id">
                <td>{{ item.concepto }}</td>
                <td style="background: #d6f4ff">
                  {{ parseFloat(item.monto_pr).toFixed(2) }}
                </td>
                <td style="background: #d6f4ff" v-if="mostrarImpuesto">
                  {{ parseFloat(item.igv_pr).toFixed(2) }}
                </td>
                <td style="background: #d6f4ff">
                  {{ parseFloat(item.total_pr).toFixed(2) }}
                </td>
                <td style="background: #ffd6d6">
                  {{ parseFloat(item.monto_op).toFixed(2) }}
                </td>
                <td style="background: #ffd6d6" v-if="mostrarImpuesto">
                  {{ parseFloat(item.igv_op).toFixed(2) }}
                </td>
                <td style="background: #ffd6d6">
                  {{ parseFloat(item.total_op).toFixed(2) }}
                </td>
                <td>
                  <center>{{ item.facturado == true ? "SI" : "NO" }}</center>
                </td>
                <td>
                  <v-icon small color="orange" @click="editIngreso(item)">
                    mdi-pencil
                  </v-icon>

                  <v-icon
                    small
                    color="red"
                    v-if="item.facturado == false"
                    @click="delIngreso(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!--  -->
    <v-dialog v-model="dialogIngreso" width="800" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Datos del servicio
          <v-spacer></v-spacer>
          <v-btn icon color="default" @click="dialogIngreso = !dialogIngreso">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="frmIngreso">
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    v-model="ingresos.concepto"
                    label="Concepto"
                    :rules="[(v) => !!v || 'Dato es requerido']"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    v-if="mostrarImpuesto"
                    @change="calcular()"
                    v-model="ingresos.statusCalcula"
                    dense
                    :label="`Calcula ${$store.state.enterprises.impuesto.nombre_impuesto}`"
                  ></v-switch>
                  <!-- <p v-else>NO HA CONFIGURADO EL IMPUESTOS A LA VENTAS. Ir al Módulo de Configuración</p> -->
                </v-col>

                <v-col cols="12" md="4" v-if="opFlag">
                  <v-text-field
                    @keyup="calcular()"
                    v-model="ingresos.montoop"
                    :rules="[(v) => !!v || 'Dato es requerido']"
                    type="number"
                    label="Monto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" v-if="opFlag">
                  <v-text-field
                    readonly
                    v-model="ingresos.igvop"
                    type="number"
                    :label="`${$store.state.enterprises.impuesto.nombre_impuesto}`"
                    v-if="mostrarImpuesto"
                  ></v-text-field>
                  <p v-else>
                    NO HA CONFIGURADO EL IMPUESTOS A LA VENTAS. Ir al Módulo de
                    Configuración
                  </p>
                </v-col>

                <v-col cols="12" md="4" v-if="opFlag">
                  <v-text-field
                    v-model="ingresos.totalop"
                    type="number"
                    label="Total"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" v-if="prFlag">
                  <v-text-field
                    @keyup="calcular()"
                    v-model="ingresos.montopr"
                    :rules="[(v) => !!v || 'Dato es requerido']"
                    type="number"
                    label="Monto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" v-if="prFlag && mostrarImpuesto">
                  <v-text-field
                    readonly
                    v-model="ingresos.igvpr"
                    type="number"
                    :label="`${$store.state.enterprises.impuesto.nombre_impuesto}`"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" v-if="prFlag">
                  <v-text-field
                    v-model="ingresos.totalpr"
                    type="number"
                    label="Total"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-btn
                    v-if="this.statusBtn == 1"
                    @click.native="setIngresos()"
                    block
                    color="success"
                    elevation="0"
                    >Agregar servicio</v-btn
                  >
                  <v-btn
                    v-if="this.statusBtn == 2"
                    @click.native="editIngresos()"
                    block
                    color="success"
                    elevation="0"
                    >Guardar Cambios</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  -->
    <v-dialog max-width="90%" v-model="dialogDebs" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Abonos del cliente
          <v-spacer></v-spacer>

          <v-btn icon color="default" @click="dialogDebs = !dialogDebs">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headersdebs" :items="house.debscliente">
            <template v-slot:[`item.accion`]="{ item }">
              <v-icon
                class="btn_add mr-2"
                small
                color="default"
                @click="openDoc(item.path)"
                >mdi-eye</v-icon
              >
              <v-icon
                class="btn_add mr-2"
                small
                color="warning"
                v-if="item.status == 1"
                @click="editarDebs(item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                class="btn_add mr-2"
                small
                color="red"
                v-if="item.status == 1"
                @click="_delDebsClient(item.id)"
                >mdi-delete</v-icon
              >
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                block
                v-if="item.status == 2"
                small
                class=""
                color="success"
                outlined
              >
                <v-icon left> mdi-check </v-icon>
                Confirmado
              </v-chip>
              <v-chip
                block
                v-if="item.status == 1"
                small
                class=""
                color="orange"
                outlined
              >
                <v-icon left> mdi-warning </v-icon>
                Por Confirmar
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="_newDebs()">
            CARGAR NUEVO ABONO
          </v-btn>
          <v-btn color="error" @click="dialogDebs = !dialogDebs">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <v-dialog max-width="80%" v-model="dialogNewDebs" v-if="dialogNewDebs">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Cargar un Nuevo abonos
          <v-spacer></v-spacer>
          <v-btn icon color="default" @click="dialogNewDebs = !dialogNewDebs">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="frmNuevoAbono">
              <v-row dense>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Fecha de Operación"
                    prepend-icon="mdi-calendar"
                    type="date"
                    v-model="date"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="number"
                    label="No. Operación del Voucher"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    :items="$store.state.itemsCoinsList"
                    v-model="id_coins"
                    item-text="acronym"
                    item-value="id"
                    label="Moneda "
                    @change="obtenerMoneda()"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" v-if="sufmoneda != 'USD'">
                  <v-text-field
                    v-model="tipocambio"
                    type="number"
                    label="Tipo de Cambio"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    @change="calcularMontoDolar()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="monto_abonado"
                    type="number"
                    label="Monto Ingresado al Banco"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    @change.native="calcularMontoDolar()"
                    :prefix="sufmoneda"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3" v-if="sufmoneda != 'USD'">
                  <v-text-field
                    v-model="monto"
                    type="number"
                    label="Monto de la Factura (Solo Lectura)"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    prefix="USD"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!--  -->
              <v-row>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    :items="$store.state.itemsDataBanksList"
                    v-model="id_banks"
                    item-text="acronym"
                    item-value="id"
                    label="Banco Origen"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    :items="
                      ($store.state.bancos.cuentas || []).filter(
                        (v) => v.symbol == sufmoneda
                      )
                    "
                    v-model="id_cuenta_pic"
                    label="Cuenta Destino (PIC)"
                    item-text="label"
                    item-value="id"
                    :rules="[(v) => !!v || 'Datos Requerido']"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-textarea
                    auto-grow
                    rows="1"
                    append-outer-icon="mdi-comment"
                    v-model="comentario"
                    label="Comentario"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="3">
                  <v-file-input
                    v-model="payfile"
                    show-size
                    @change="uploadFile()"
                    :success-messages="msgfile"
                    :error-messages="errfile"
                    :loading="loadingFile"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    label="Cargar Archivo"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loading" @click="_setDebsClient()"
            >GUARDAR ABONO</v-btn
          >

          <v-btn color="red" dark @click="dialogNewDebs = !dialogNewDebs"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <v-dialog max-width="80%" v-model="dialogDebsEdit">
      <v-card>
        <v-card-title>
          Cargar un nuevo abono
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="frmNuevoAbono">
              <v-row dense>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Fecha de Operación"
                    prepend-icon="mdi-calendar"
                    type="date"
                    v-model="date"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="number"
                    label="No. Operación"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="monto"
                    type="number"
                    label="Monto En Dolares"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    suffix="USD"
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="tipocambio"
                    type="number"
                    label="Tipo de Cambio"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    @change="calcularMontoDolar()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    :items="$store.state.itemsDataBanksList"
                    v-model="id_banks"
                    item-text="acronym"
                    item-value="id"
                    label="Banco Origen"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    :items="
                      ($store.state.bancos.cuentas || []).filter(
                        (v) => v.symbol == sufmoneda
                      )
                    "
                    v-model="id_cuenta_pic"
                    label="Cuenta Destino (PIC)"
                    item-text="label"
                    item-value="id"
                    :rules="[(v) => !!v || 'Datos Requerido']"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    :items="$store.state.itemsCoinsList"
                    v-model="id_coins"
                    item-text="acronym"
                    item-value="id"
                    label="Moneda En cuenta de Banco"
                    @change="obtenerMoneda()"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="monto_abonado"
                    type="number"
                    label="Monto En cuenta de Banco"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    @change.native="calcularMontoDolar()"
                    :prefix="sufmoneda"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    rows="2"
                    v-model="comentario"
                    label="Comentario"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="9">
                  <v-file-input
                    v-if="!boolFile"
                    v-model="payfile"
                    show-size
                    label="Adjuntar Pago"
                  >
                  </v-file-input>

                  <v-chip
                    block
                    v-if="boolFile"
                    large
                    class=""
                    color="success"
                    outlined
                  >
                    <v-icon left> mdi-check </v-icon>
                    Archivo cargado éxitosamente
                  </v-chip>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    :disabled="boolFile || payfile == []"
                    color="blue-grey"
                    block
                    class="ma-2 white--text"
                    large
                    @click="_uploadFile()"
                  >
                    Subir
                    <v-icon right dark> mdi-cloud-upload </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="actualizar()">GUARDAR ABONO</v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined color="red" @click="dialogDebsEdit = !dialogDebsEdit"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- --------------------------- -->
    <v-dialog
      v-model="dialogFacturar"
      scrollable
      persistent
      :overlay="false"
      max-width="70%"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> GENERAR FACTURA </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                v-model="selected"
                :headers="headersFacturas"
                :items="conceptos.filter((v) => !v.facturado)"
                show-select
                item-key="id"
              >
              </v-data-table>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="$store.state.itemsCoinsList"
                item-text="symbol"
                item-value="id"
                label="Moneda"
                placeholder="Seleccione una moneda"
                v-model="id_coins"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="$store.state.branchs"
                item-text="trade_name"
                item-value="id"
                label="Sucursal"
                placeholder="Seleccione una sucursal"
                v-model="id_branch"
                @change="obtenerDatos()"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            :loading="obtenerDatosHouse"
            :disabled="id_branch == '' || selected.length == 0"
            @click="generarDocumento()"
          >
            Generar Factura
          </v-btn>
          <v-btn color="warning" @click="dialogFacturar = !dialogFacturar"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FACTURAS FISCALES-->
    <v-dialog max-width="80%" v-model="dialogFacturasFiscales">
      <v-card>
        <v-card-title>
          Facturas y Proformas Fiscales de {{ house.consigner }}
          <v-spacer></v-spacer>
          <v-btn outlined @click="cargarProformaFiscal" color="primary"
            >Cargar Nueva</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersFacturasFiscales"
            :items="lstFacturasFiscales"
            dense
            item-key="id"
            class="elevation-0"
            :loading="isDataTableLoading"
          >
            <template v-slot:[`item.originalname`]="{ item }">
              <v-icon v-if="item.type == 'pdf'" color="red" class="mr-2"
                >mdi-file-pdf</v-icon
              >
              <v-icon
                v-if="
                  item.type == 'png' ||
                  item.type == 'jpg' ||
                  item.type == 'jpeg'
                "
                color="blue-grey darken-1"
                class="mr-2"
                >mdi-file-image</v-icon
              >
              {{
                item.tipo_pago == 1
                  ? "FACTURA"
                  : "" || item.tipo_pago == 2
                  ? "PERFORMANCE"
                  : "OTRO"
              }}
              -
              {{ item.nro_factura }}
            </template>
            <template v-slot:[`item.path`]="{ item }">
              <v-btn icon small>
                <v-icon
                  class="btn_add mr-2"
                  dense
                  large
                  color="grey"
                  @click.native="openDoc(item.path)"
                >
                  mdi-eye
                </v-icon></v-btn
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon small>
                <v-icon
                  class="btn_add mr-2"
                  dense
                  large
                  color="red"
                  @click.native="eliminarProformaFiscal(item.id)"
                >
                  mdi-delete
                </v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--PROFORMA FISCAL-->
    <v-dialog width="80%" v-model="dialogProformaFiscal">
      <v-card>
        <v-card-title>
          <h3>Subir Proformas Fiscales de {{ house.consigner }}</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-radio-group v-model="proformaFiscal.tipo_pago" row>
                  <v-radio label="Factura" value="1"></v-radio>
                  <v-radio label="Performance" value="2"></v-radio>
                  <v-radio label="Otro" value="3"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Fecha de Operación"
                  prepend-icon="mdi-calendar"
                  type="date"
                  v-model="proformaFiscal.fecha"
                  :rules="[(v) => !!v || 'Dato Requerido']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="proformaFiscal.nro_factura"
                  :label="`Nro de ${
                    proformaFiscal.tipo_pago == 1
                      ? 'Factura'
                      : '' || proformaFiscal.tipo_pago == 2
                      ? 'Performance'
                      : 'Otro'
                  }`"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-file-input
                  v-model="proformaFiscal.archivo"
                  @change="uploadFileProformaFiscal"
                  show-size
                  :label="`Adjuntar File de ${
                    proformaFiscal.tipo_pago == 1
                      ? 'Factura'
                      : '' || proformaFiscal.tipo_pago == 2
                      ? 'Performance'
                      : 'Otro'
                  }`"
                  :success-messages="msgfile"
                  :error-messages="errfile"
                  :loading="loadingFile"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="registrarProformaFiscal()"
            :disabled="!boolFile"
          >
            GUARDAR
            {{
              proformaFiscal.tipo_pago == 1
                ? "Factura"
                : "" || proformaFiscal.tipo_pago == 2
                ? "Performance"
                : "Otro"
            }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="red"
            @click="dialogProformaFiscal = !dialogProformaFiscal"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: {
    statusBtn: {
      default: 0,
      type: Number,
    },
    prFlag: {
      default: false,
      type: Boolean,
    },
    opFlag: {
      default: false,
      type: Boolean,
    },
    ingreso_pr: {
      default: 0,
      type: Number,
    },
    ingreso_op: {
      default: 0,
      type: Number,
    },
  },

  data: () => {
    return {
      openedPanel: null,
      payfile: [],
      date: "",
      number: "",
      monto: "",
      tipocambio: "",
      id_banks: "",
      id_cuenta_pic: "",
      id_coins: "",
      monto_abonado: "",
      comentario: "",
      errfile: "",
      sufmoneda: "",
      msgfile: "",
      id_branch: "",
      obtenerDatosHouse: null,
      loadingFile: false,
      dialogFacturar: false,
      dialogNewDebs: false,
      dialogIngreso: false,
      dialogDebs: false,
      dialogDebsEdit: false,
      loading: false,
      boolFile: false,
      house: {
        debscliente: [],
      },
      ingresos: {
        id: null,
        concepto: "",
        statusCalcula: false,
        opcion: null,
        numero: "",
        fecha: moment(new Date()).format("YYYY-MM-DD"),
        montoop: 0,
        igvop: 0,
        totalop: 0,
        montopr: 0,
        igvpr: 0,
        totalpr: 0,
      },
      headersdebs: [
        {
          text: "Documento",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Fecha",
          value: "date",
        },
        {
          text: "Monto En Dolares",
          value: "monto_letra",
        },
        {
          text: "Monto en Destino",
          value: "monto_destino",
        },
        {
          text: "Tipo Moneda En Destino.",
          value: "symbol_destino",
        },
        {
          text: "Comentario del Usuario.",
          value: "comentario_usuario",
        },
        {
          text: "Comentario de Admin.",
          value: "comentario_admin",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Ver",
          value: "accion",
        },
      ],
      headersFacturas: [
        { text: "Concepto", value: "concepto" },
        { text: "Monto Pr", value: "monto_pr" },
        { text: "IGV Pr", value: "igv_pr" },
        { text: "Total Pr", value: "total_pr" },
        { text: "Monto Op", value: "monto_op" },
        { text: "IGV Op", value: "igv_op" },
        { text: "Total Op", value: "total_op" },
      ],
      conceptos: [],
      selected: [],

      isDataTableLoading: false,
      dialogFacturasFiscales: false,
      dialogProformaFiscal: false,
      headersFacturasFiscales: [
        { text: "Nombre", value: "originalname" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "path" },
        { text: "Acción", value: "action" },
      ],
      lstFacturasFiscales: [],
      proformaFiscal: {
        id_consigner: "",
        nameconsigner: "",
        tipo_pago: "1",
        nro_factura: "",
        fecha: moment().format("YYYY-MM-DD"),
        archivo: "",
        id_master: "",
        id_correlativo: "",
      },
      mostrarImpuesto: true,
    };
  },
  async mounted() {
    setTimeout(() => {
      this.headersFacturas[2].text =
        this.$store.state.enterprises.impuesto.nombre_impuesto;
      this.headersFacturas[5].text =
        this.$store.state.enterprises.impuesto.nombre_impuesto;
      this.mostrarImpuesto =
        this.$store.state.enterprises.impuesto.mostrarimpuesto;
      if (!this.mostrarImpuesto) {
        this.headersFacturas.splice(2, 1);
        this.headersFacturas.splice(4, 1);
      }
    }, 2000);
  },
  methods: {
    _openDebs(house) {
      this.house = house;
      this.dialogDebs = true;
    },
    openDoc(path) {
      window.open(path, "_blank");
    },

    _newDebs() {
      this.loading = false;
      this.boolFile = false;
      this.payfile = [];
      this.date = "";
      this.number = "";
      this.monto = "";
      this.tipocambio = "";
      this.id_banks = "";
      this.id_cuenta_pic = "";
      this.id_coins = "";
      this.monto_abonado = "";
      this.comentario = "";
      this.$store.state.files.payPath = null;
      this.dialogNewDebs = true;
    },
    abrirExpandIngreso(index) {
      this.$store.state.controlGastos.listControlGastos[0].master_houses[
        index
      ].isExpanded =
        !this.$store.state.controlGastos.listControlGastos[0].master_houses[
          index
        ].isExpanded;
    },
    editIngreso(item) {
      this.statusBtn = 2;
      this.ingresos = {
        id: item.id,
        concepto: item.concepto,
        statusCalcula: this.igv_pr != 0 || this.igv_op != 0 ? true : false,
        numero: "",
        fecha: moment(new Date()).format("YYYY-MM-DD"),
        montoop: item.monto_op,
        igvop: item.igv_op,
        totalop: item.total_op,
        montopr: item.monto_pr,
        igvpr: item.igv_pr,
        totalpr: item.total_pr,
        opcion: item.tipo_pago,
        numero: item.numero,
        fecha: item.fecha,
      };
      this.dialogIngreso = true;
    },
    abrirModalFacturar(house) {
      this.house = house;
      this.selected = [];
      this.id_branch = "";
      this.conceptos = house.ingresos;
      this.dialogFacturar = true;
    },
    async nuevoControlGasto(house) {
      house.code_master = this.$route.params.code_master;
      let vm = this;
      await vm.setControl(house);
      vm.$store.state.spiner = false;
    },
    obtenerMoneda() {
      this.sufmoneda = this.$store.state.itemsCoinsList.filter(
        (v) => v.id == this.id_coins
      )[0].symbol;
      this.sufmoneda == "USD"
        ? (this.tipocambio = 1)
        : (this.tipocambio = this.tipocambio);
    },
    calcularMontoDolar() {
      this.monto = parseFloat(
        (this.monto_abonado ? this.monto_abonado : 0) / this.tipocambio
      ).toFixed(2);
    },
    async _setDebsClient() {
      this.calcularMontoDolar();
      this.loading = true;
      if (this.$refs.frmNuevoAbono.validate()) {
        var vm = this;
        vm.$swal({
          icon: "info",
          title: "Cargando pago...",
          text: "Por favor espere",
        });
        var data = {
          id_house: this.house.id_house,
          date: vm.date,
          number: vm.number,
          id_banks: vm.id_banks,
          monto: vm.monto,
          comentario_usuario: vm.comentario,
          id_path: vm.$store.state.files.payPath,
          id_cuenta_pic: vm.id_cuenta_pic,
          tipocambio: vm.tipocambio,
          id_moneda_destino: vm.id_coins,
          monto_destino: vm.monto_abonado,
          nro_operacion: vm.number,
        };
        var config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "setDebsClient",

          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config)
          .then(function (response) {
            vm.dialogNewDebs = false;
            vm.dialogDebs = false;
            vm.listControlGasto(vm.$route.params.code_master);

            vm.cleandData();

            // vm.dialogDebs = true;
            vm.$swal({
              icon: "success",
              title: "Pago Cargado",
              text: "El pago ha sido cargado correctamente",
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.loading = false;
    },
    async listControlGasto() {
      this.$store.state.spiner = true;
      await this.getListControlGastos(this.$route.params.code_master);
      this.$store.state.spiner = false;
    },
    async uploadFile() {
      this.msgfile = "";
      this.errfile = "";
      if (this.payfile) {
        this.loadingFile = true;
        await this._uploadFile(this.payfile);
        this.loadingFile = false;
        this.$store.state.files.payPath
          ? (this.msgfile = "Archivo cargado")
          : (this.errfile = "Comuniquese con el admistrador");
      } else {
        this.errfile = "Dato Requerido";
      }
    },
    nuevoIngreso(house) {
      this.statusBtn = 1;
      this.ingresos = {
        id: null,
        concepto: "",
        statusCalcula: false,
        opcion: null,
        numero: "",
        fecha: moment(new Date()).format("YYYY-MM-DD"),
        montoop: 0,
        igvop: 0,
        totalop: 0,
        montopr: 0,
        igvpr: 0,
        totalpr: 0,
      };
      this.house = house;

      this.dialogIngreso = true;
    },
    calcular() {
      if (this.ingresos.statusCalcula) {
        this.ingresos.igvop = parseFloat(
          (this.ingresos.montoop *
            this.$store.state.enterprises.impuesto.impuesto) /
            100
        ).toFixed(2);
        this.ingresos.igvpr = parseFloat(
          (this.ingresos.montopr *
            this.$store.state.enterprises.impuesto.impuesto) /
            100
        ).toFixed(2);
        this.ingresos.totalop =
          parseFloat(this.ingresos.montoop) + parseFloat(this.ingresos.igvop);
        this.ingresos.totalpr =
          parseFloat(this.ingresos.montopr) + parseFloat(this.ingresos.igvpr);
      } else {
        this.ingresos.igvop = 0.0;
        this.ingresos.igvpr = 0.0;
        this.ingresos.totalop =
          parseFloat(this.ingresos.montoop) + parseFloat(this.ingresos.igvop);
        this.ingresos.totalpr =
          parseFloat(this.ingresos.montopr) + parseFloat(this.ingresos.igvpr);
      }
    },
    editarDebs(item) {
      this.id = item.id;
      this.date = item.date;
      this.number = item.number;
      this.monto = item.monto;
      this.tipocambio = item.tipocambio;
      this.id_banks = item.id_banks;
      this.id_cuenta_pic = item.id_cuenta_pic;
      this.id_coins = item.id_moneda_destino;
      this.monto_abonado = item.monto_destino;
      this.comentario = item.comentario_usuario;
      this.dialogDebsEdit = true;
    },
    cleandData() {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.number = "";
      this.monto = 0;
      this.comentario = "";
      this.payfile = [];
      this.payPath = "";
      this.boolFile = false;
      this.id_cuenta_pic = "";
      this.tipocambio = 1;
      this.id_coins = "";
      this.monto_abonado = "";
      this.number = "";
    },
    async _delDebsClient(id) {
      var vm = this;

      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "delDebsClient/" + id,

        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
      };
      await axios(config)
        .then(function (response) {
          vm.listControlGasto();
          vm.dialogDebs = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async editIngresos() {
      let data = {
        code_master: this.$route.params.code_master,
        concepto: this.ingresos.concepto,
        monto_pr: this.ingresos.montopr,
        monto_op: this.ingresos.montoop,
        igv_pr: this.ingresos.igvpr,
        igv_op: this.ingresos.igvop,
        total_pr: this.ingresos.totalpr,
        total_op: this.ingresos.totalop,
        tipo_pago: this.ingresos.opcion,
        numero: this.ingresos.numero,
        fecha: this.ingresos.fecha,
        id: this.ingresos.id,
      };
      await this.actualizarIngresos(data);
      this.statusBtn = 1;
      this.dialogIngreso = false;
      this.$forceUpdate();
      this.$emit("recalcularProfit");
    },
    async delIngreso(id) {
      let data = {
        code_master: this.$route.params.code_master,
        id: id,
      };
      await this.eliminarIngreso(data);
      this.$emit("recalcularProfit");
    },
    async setIngresos() {
      // this.calcularE();
      if (this.$refs.frmIngreso.validate()) {
        var data = {
          code_master: this.$route.params.code_master,
          id_orders: this.house.id_orders,
          concepto: this.ingresos.concepto,
          monto_op: this.ingresos.montoop,
          igv_op: this.ingresos.igvop,
          total_op: this.ingresos.totalop,
          monto_pr: this.ingresos.montopr,
          igv_pr: this.ingresos.igvpr,
          total_pr: this.ingresos.totalpr,
          id_user: JSON.parse(localStorage.getItem("dataUser"))[0].id,
          tipo_pago: this.ingresos.opcion,
          numero: this.ingresos.numero,
          fecha: this.ingresos.fecha,
        };
        await this.guardarIngresos(data);
        this.dialogIngreso = false;
        this.$emit("recalcularProfit");
      }
    },
    async actualizar() {
      if (this.$refs.frmNuevoAbono.validate()) {
        var vm = this;
        vm.$swal({
          icon: "info",
          title: "Cargando pago...",
          text: "Por favor espere",
        });
        var data = {
          id_house: this.house.id_house,
          date: vm.date,
          number: vm.number,
          id_banks: vm.id_banks,
          monto: vm.monto,
          comentario_usuario: vm.comentario,
          id_path: vm.payPath,
          id_cuenta_pic: vm.id_cuenta_pic,
          tipocambio: vm.tipocambio,
          id_moneda_destino: vm.id_coins,
          monto_destino: vm.monto_abonado,
          nro_operacion: vm.number,
          id: vm.id,
        };
        var config = {
          method: "put",
          url: process.env.VUE_APP_URL_MAIN + "actualizar_debs_client",

          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config)
          .then(function (response) {
            vm.cleandData();

            // vm.dialogDebs = true;
            vm.$swal({
              icon: "success",
              title: "Pago Cargado",
              text: "El pago ha sido cargado correctamente",
            }).then((result) => {
              if (result.isConfirmed) {
                vm.dialogDebsEdit = false;
                vm.dialogDebs = false;
                vm.listControlGasto();
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    async obtenerDatos() {
      let vm = this;
      vm.obtenerDatosHouse = true;
      vm.datosFactura = [];
      var config = {
        method: "get",
        url: `${process.env.VUE_APP_URL_MAIN}data_factura/${vm.house.id_house}/${vm.id_branch}`,

        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
      };
      await axios(config).then(function (response) {
        if (response.data.statusBol == true) {
          vm.datosFactura = response.data.data[0];
        }
      });
      vm.obtenerDatosHouse = false;
    },
    async generarDocumento() {
      let vm = this;

      vm.datosFactura.coins = vm.$store.state.itemsCoinsList.filter(
        (v) => (v.id = vm.id_coins)
      )[0].symbol;
      vm.datosFactura.details = vm.selected.map((element) => {
        return {
          concepto: element.concepto,
          id_orders: element.id_orders,
          igv: element.igv_op,
          monto: element.monto_op,
          total: element.total_op,
        };
      });
      vm.id_house = vm.selected[0].id_house;
      let total = 0;
      let total_igv = 0;
      let total_monto = 0;

      vm.selected.forEach((element) => {
        total = parseFloat(total) + parseFloat(element.total_op);
        total_igv = parseFloat(total_igv) + parseFloat(element.igv_op);
        total_monto = parseFloat(total_monto) + parseFloat(element.monto_op);
      });

      vm.datosFactura.total = parseFloat(total).toFixed(2);
      vm.datosFactura.url_logo = JSON.parse(
        localStorage.getItem("dataUser")
      )[0].path;
      vm.datosFactura.total_igv = parseFloat(total_igv).toFixed(2);
      vm.datosFactura.total_monto = parseFloat(total_monto).toFixed(2);

      vm.obtenerDatosHouse = true;
      let data = vm.datosFactura;
      // console.log(vm.datosFactura);
      // return;
      var config = {
        method: "post",
        url: `${process.env.VUE_APP_URL_MAIN}generar_factura`,
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      let res = await axios(config).then(function (response) {
        return response;
      });

      if (res.data.statusBol == true) {
        vm.registrarFactura(res.data);
        vm.dialogFacturar = false;
        vm.id_branch = "";
      }
      vm.obtenerDatosHouse = false;
    },
    async registrarFactura(data) {
      let vm = this;
      let dataFactura = {
        id_house: vm.id_house,
        id_coins: vm.id_coins,
        nro_factura: data.nro_factura,
        url_documento: process.env.VUE_APP_URL_MAIN + data.path,
        total_monto: vm.datosFactura.total_monto,
        total_igv: vm.datosFactura.total_igv,
        total: vm.datosFactura.total,
        details: vm.selected,
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "registrar_factura",
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: dataFactura,
      };
      await axios(config);
      window.open(process.env.VUE_APP_URL_MAIN + data.path, "_blank");
      vm.listControlGasto();
    },
    isBotonEmitirProformaDisabled(house = {}) {
      let disabled = false;

      if (
        house.total_total_pr_ingresos == 0 &&
        house.total_total_op_ingresos == 0
      ) {
        disabled = !disabled;
      } else if (house.ingresos.every((v) => v.facturado)) {
        disabled = !disabled;
      }

      return disabled;
    },
    getDeudaActual(data = {}) {
      let { ingresos = [], total_total_op_ingresos = 0 } = data;

      let abono = ingresos.reduce((suma, v) => {
        return v.facturado ? suma + (parseFloat(v.total_op) || 0) : suma;
      }, 0);

      let deuda =
        parseFloat(total_total_op_ingresos) - parseFloat(abono.toFixed(2));

      return deuda != 0 ? deuda.toFixed(2) : 0;
    },
    async abrirModalFacturasFiscales(house = {}) {
      var vm = this;

      vm.house = house;
      vm.dialogFacturasFiscales = true;

      vm.isDataTableLoading = true;
      await vm.getFacturasFiscales(house);
      vm.isDataTableLoading = false;
    },
    cargarProformaFiscal() {
      var vm = this;

      vm.boolFile = false;
      vm.msgfile = "";
      vm.errfile = "";
      vm.proformaFiscal = {
        ...vm.proformaFiscal,
        tipo_pago: "1",
        nro_factura: "",
        fecha: moment().format("YYYY-MM-DD"),
        archivo: null,
        status: 1,
        id_house: vm.house.id_house,
      };

      vm.dialogProformaFiscal = true;
    },
    async uploadFileProformaFiscal() {
      var vm = this;

      vm.boolFile = false;
      vm.msgfile = "";
      vm.errfile = "";
      if (vm.proformaFiscal.archivo) {
        vm.loadingFile = true;
        await vm._uploadFile(vm.proformaFiscal.archivo);
        vm.loadingFile = false;

        if (vm.$store.state.files.payPath) {
          vm.boolFile = true;
          vm.msgfile = "Archivo cargado";
          vm.proformaFiscal = {
            ...vm.proformaFiscal,
            id_path: vm.$store.state.files.payPath,
          };
        } else {
          vm.errfile = "Comuniquese con el administrador";
        }
      } else {
        vm.errfile = "Dato Requerido";
      }
    },
    async registrarProformaFiscal() {
      var vm = this;

      vm.$swal({
        icon: "info",
        title: "Guardando...",
        text: "Por favor, espere",
      });

      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "setProformaFiscal",
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: vm.proformaFiscal,
      };

      await axios(config).then(async function (response) {
        // console.log(response);
        localStorage.setItem("auth-token", response.data.token);

        if (response.data.estadoflag) {
          vm.$swal({
            icon: "success",
            title: "Documento Cargado",
            text: "El documento ha sido cargado correctamente",
          });

          await vm.getFacturasFiscales(vm.house);
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.mensaje,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((resSwal) => {
            if (resSwal.isConfirmed && response.data.status == "401") {
              router.push({ name: "Login" });
              setTimeout(() => {
                window.location.reload();
              }, 10);
            }
          });
        }
      });

      vm.dialogProformaFiscal = !vm.dialogProformaFiscal;
    },
    async getFacturasFiscales(house = {}) {
      var vm = this;

      var config = {
        method: "get",
        url:
          process.env.VUE_APP_URL_MAIN +
          `getFacturasFiscales?id_house=${house.id_house}`,
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
      };

      await axios(config)
        .then(function (response) {
          // console.log(response);
          localStorage.setItem("auth-token", response.data.token);

          if (!!response.data.data[0].estadoflag) {
            vm.lstFacturasFiscales = response.data.data;
          } else {
            vm.lstFacturasFiscales = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async eliminarProformaFiscal(id = "") {
      var vm = this;

      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "delProformaFiscal/" + id,
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
      };

      await axios(config)
        .then(async function (response) {
          // console.log(response);
          localStorage.setItem("auth-token", response.data.token);

          if (response.data.estadoflag) {
            vm.$swal({
              icon: "success",
              title: "Información",
              text: response.data.mensaje,
            });

            vm.isDataTableLoading = true;
            await vm.getFacturasFiscales(vm.house);
            vm.isDataTableLoading = false;
          } else {
            Swal.fire({
              icon: "error",
              text: response.data.mensaje,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((resSwal) => {
              if (resSwal.isConfirmed && response.data.status == "401") {
                router.push({ name: "Login" });
                setTimeout(() => {
                  window.location.reload();
                }, 10);
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    ...mapActions([
      "setControl",
      "guardarIngresos",
      "_uploadFile",
      "getListControlGastos",
      "delDebsClient",
      "data_factura",
      "generar_factura",
      "registrar_factura",
      "actualizarIngresos",
      "eliminarIngreso",
    ]),
  },
};
</script>

<style></style>
